import { reactive, toRaw, onBeforeMount } from 'vue'
import { cmsMessage, cmsNotice } from './utils'
import store from '@/store'

export const useCheckStateAndEvent = (enhancerMap = {}) => {
  const check = reactive({
    // 这里是选择审批人的数据
    indeterminate: false,
    checkedList: [],
    totalCheckedList: [],
    checkedMap: {},
    checkAll: false,
    searchLbwList: [], // 展示的审批人
    totalSearchList: [], // 全部的审批人，做个备份
    searchLbwVal: undefined,
    staffList: [],
    checkedListRight: [],
    checkType: 0 //0-审批人，1-特权用印人
  })
  const onCheckAllChange = e => {
    // 审批人全选回调

    if (e.target.checked) {
      check.checkedList = toRaw(check.searchLbwList).map(item => {
        check.checkedMap[item.value] = true
        return item.value
      })
      check.checkedListRight = toRaw(check.searchLbwList).map(item => {
        check.checkedMap[item.value] = true
        return item
      })
    } else {
      check.checkedList = []
      check.checkedListRight = []
      check.searchLbwList.forEach(item => {
        check.checkedMap[item.value] = false
        return item.value
      })
    }
    check.checkAll = e.target.checked
    check.indeterminate = check.checkAll ? false : !!check.checkedList.length
    enhancerMap.onCheckAllChange && enhancerMap.onCheckAllChange()
  }
  const onChange = e => {
    // 审批人单个选择的回调
    // console.log("e",e.target);
    const target = e.target
    check.checkedMap[target.value] = target.checked
    const currtItem = check.totalSearchList.find(item => {
      return item.id === target.value
    })
    if (target.checked) {
      check.checkedListRight.push(currtItem)
      check.checkedMap[currtItem.value] && check.checkedList.push(currtItem.value)
    } else {
      check.checkedListRight.forEach((item, index) => {
        if (item.id == target.value) {
          check.checkedListRight.splice(index--, 1)
          // check.checkedList.splice(index--,1)
        }
      })
      check.checkedList.forEach((item, index) => {
        if (item == target.value) {
          check.checkedList.splice(index--, 1)
        }
      })
    }
    check.checkAll = check.checkedList.length === check.searchLbwList.length
    check.indeterminate = check.checkAll ? false : !!check.checkedList.length
    enhancerMap.onChange && enhancerMap.onChange()
  }
  const reloadSearchLbwList = () => {
    // 重置选择审批人的列表
    const approvalStaff = toRaw(check.staffList)
    if (approvalStaff.length) {
      const approvalMap = {}
      approvalStaff.forEach(item => {
        approvalMap[item.id] = item
      })
      check.searchLbwList = check.totalSearchList.filter(item => {
        return !approvalMap[item.id]
      })
    } else {
      check.searchLbwList = check.totalSearchList.map(item => item)
    }
  }
  const closeApprovaModel = () => {
    enhancerMap.closeApprovaModel && enhancerMap.closeApprovaModel()
    check.checkedList = []
    check.checkedListRight = []
    check.checkAll = false
    check.searchLbwVal = undefined
    reloadSearchLbwList()
  }
  const selectApprovalFn = () => {
    // 选择审批人，确定的回调
    const validateArr = []
    for (let key in check.checkedMap) {
      check.checkedMap[key] && validateArr.push(~~key)
    }
    if (!validateArr.length) {
      cmsNotice('error', check.checkType ? '请选择特权用印人' : '请选择审批人')
      return
    }
    enhancerMap.selectApprovalFn && enhancerMap.selectApprovalFn()
    const approvalMap = {}
    check.totalSearchList.forEach(item => {
      approvalMap[item.id] = item
    })
    // check.checkedList = validateArr
    // console.log(check.checkedList);
    check.staffList = [...check.staffList, ...check.checkedList.map(item => approvalMap[item])]
    enhancerMap.changeStaffList && enhancerMap.changeStaffList()
    check.indeterminate = false
    check.checkedList = []
    check.checkedListRight = []
    check.checkAll = false
    check.searchLbwVal = undefined
    reloadSearchLbwList()
  }

  const deleteApprovalFn = index => {
    // 删除选择的审批人
    const approvalMap = {}
    check.searchLbwList.forEach(item => {
      approvalMap[item.id] = item
    })
    check.staffList.splice(index, 1)
    check.staffList = [...check.staffList, ...check.checkedList.map(item => approvalMap[item])]
    enhancerMap.changeStaffList && enhancerMap.changeStaffList()
    enhancerMap.deleteApprovalFn && enhancerMap.deleteApprovalFn()
    reloadSearchLbwList()
  }
  const searchApprovalList = () => {
    // 查找审批人
    reloadSearchLbwList()
    if (check.searchLbwVal) {
      if (enhancerMap.isSearchPhone) {
        const searchNameList = check.searchLbwList.filter(item => item.name.includes(check.searchLbwVal))
        const searchPhoneList = check.searchLbwList.filter(item => item.mobile.includes(check.searchLbwVal))
        check.searchLbwList = Array.from(new Set([...searchNameList, ...searchPhoneList])).map(item => item)
      } else {
        check.searchLbwList = check.searchLbwList.filter(item => item.name.includes(check.searchLbwVal))
      }
    }
    check.checkAll = check.searchLbwList.length === check.checkedList.length
    check.indeterminate = check.checkAll ? false : !!check.checkedList.length
    enhancerMap.searchApprovalList && enhancerMap.searchApprovalList()
  }

  const openApprovalModal = () => {
    enhancerMap.openApprovalModal && enhancerMap.openApprovalModal()
    check.checkedMap = {}
    check.checkedList = []
    check.checkedListRight = []
    check.searchLbwList.forEach(item => {
      check.checkedMap[item.value] = false
    })
  }

  const initialApproalList = staffList => {
    console.log('initialApproalList')
    console.log(staffList)
    check.checkedList = []
    check.checkedListRight = []
    check.staffList = staffList || []
    check.checkAll = false
    check.searchLbwVal = undefined
    check.indeterminate = false
    reloadSearchLbwList()
  }

  // 右边列表点击事件
  const onChangeList = target => {
    check.checkedMap[target.value] = false
    check.checkedListRight.forEach((item, index) => {
      if (item.id == target.value) {
        check.checkedListRight.splice(index--, 1)
      }
    })
    check.checkedList = []
    check.checkedListRight.forEach(item => {
      check.checkedList.push(item.value)
    })
    enhancerMap.onChangeList && enhancerMap.onChangeList()
  }

  return {
    check,
    onChange,
    onCheckAllChange,
    reloadSearchLbwList,
    selectApprovalFn,
    closeApprovaModel,
    searchApprovalList,
    deleteApprovalFn,
    openApprovalModal,
    initialApproalList,
    onChangeList
  }
}
const getId = () => {
  return (
    'field' +
    (Math.floor(Math.random() * (99999 - 10000)) + 10000).toString() +
    new Date()
      .getTime()
      .toString()
      .substring(5)
  )
}
export const loadInitFrom = value => {
  let formItems =
    value && value == 2
      ? [
          {
            title: '流程主题',
            controlName: 'TextInput',
            controlType: '单行文本',
            configName: 'TextInput',
            valueType: 'String',
            type: 'FILE_NAME',
            props: {
              disabledRequired: true,
              disabledTitle: true,
              disabledDel: true,
              required: true,
              placeholder: '请输入',
              enablePrint: true
            },
            id: getId()
          },
          {
            title: '姓名',
            controlName: 'TextInput',
            controlType: '单行文本',
            configName: 'TextInput',
            valueType: 'String',
            type: 'NAME',
            props: {
              disabledRequired: true,
              disabledTitle: true,
              disabledDel: true,
              required: true,
              placeholder: '请输入姓名',
              enablePrint: true
            },
            id: getId()
          },
          {
            title: '手机号',
            controlName: 'Phone',
            controlType: '电话',
            configName: 'Phone',
            valueType: 'String',
            type: 'MOBILE',
            props: {
              disabledRequired: true,
              disabledTitle: true,
              disabledDel: true,
              required: true,
              placeholder: '请输入手机号',
              enablePrint: true
            },
            id: getId()
          },
          {
            type: 'SEAL_INFO',
            controlName: 'SealInfo',
            controlType: '印章详情控件组',
            configName: 'SealInfo',
            // title: '印章详情',
            valueType: 'Array',
            id: getId(),
            props: {
              disabledDel: true,
              items: [
                {
                  title: '申请印章',
                  controlName: 'SelectInput',
                  configName: 'SelectInput',
                  controlType: '单选',
                  valueType: 'Array',
                  props: {
                    required: true
                  },
                  id: getId()
                },
                {
                  title: '申请次数',
                  controlName: 'NumberInput',
                  configName: 'NumberInput',
                  controlType: '数字',
                  valueType: 'Number',
                  props: {
                    required: true,
                    showChinese: true,
                    precision: true // 保留小数点
                  },
                  id: getId()
                }
              ]
            }
          },
          {
            title: '申请事由',
            controlName: 'TextareaInput',
            controlType: '多行文本',
            configName: 'TextareaInput',
            valueType: 'String',
            props: {
              disabledDel: false,
              required: false
            },
            id: getId()
          },
          {
            title: '预计用印日期',
            controlName: 'DateTime',
            controlType: '日期',
            configName: 'DateTime',
            valueType: 'Date',
            props: {
              required: false,
              disabledDel: false,
              format: 'yyyy-MM-DD'
            },
            id: getId()
          },
          {
            title: '上传文件',
            controlName: 'FileUpload',
            controlType: '附件',
            configName: 'FileUpload',
            valueType: 'Array',
            type: 'FILE',
            props: {
              disabledDel: true,
              disabledTitle: true,
              required: false,
              placeholder: '请上传文件',
            },
            id: getId()
          }
        ]
      : [
          {
            title: '流程主题',
            controlName: 'TextInput',
            controlType: '单行文本',
            configName: 'TextInput',
            valueType: 'String',
            type: 'FILE_NAME',
            props: {
              disabledRequired: true,
              disabledTitle: true,
              disabledDel: true,
              required: true,
              placeholder: '请输入',
              enablePrint: true
            },
            id: getId()
          },
          {
            type: 'SEAL_INFO',
            controlName: 'SealInfo',
            controlType: '印章详情控件组',
            configName: 'SealInfo',
            // title: '印章详情',
            valueType: 'Array',
            id: getId(),
            props: {
              disabledDel: true,
              items: [
                {
                  title: '申请印章',
                  controlName: 'SelectInput',
                  configName: 'SelectInput',
                  controlType: '单选',
                  valueType: 'Array',
                  props: {
                    required: true
                  },
                  id: getId()
                },
                {
                  title: '申请次数',
                  controlName: 'NumberInput',
                  configName: 'NumberInput',
                  controlType: '数字',
                  valueType: 'Number',
                  props: {
                    required: true,
                    showChinese: true,
                    precision: true // 保留小数点
                  },
                  id: getId()
                },
                {
                  title: '盖印人',
                  controlName: 'SelectInput',
                  configName: 'SelectInput',
                  controlType: '单选',
                  valueType: 'Sring',
                  props: {
                    required: true,
                    stamp: [
                      { title: '申请人', isCheck: false },
                      // { title: '保管员', isCheck: true }
                    ]
                  },
                  id: getId()
                }
              ]
            }
          },

          {
            title: '盖印方式',
            controlName: 'SelectInput',
            controlType: '单选',
            configName: 'Remote',
            valueType: 'String',
            type: 'REMOTE', //后端
            props: {
              disabledRequired: true,
              disabledDel: true,
              required: true,
              placeholder: '请选择',
              options: [
                {
                  name: '常规盖印',
                  isOpen: true,
                  childSelect: [] //范围用印与录制视频
                },
                {
                  name: '连续盖印',
                  isOpen: true,
                  childSelect: [1, 2] //范围用印与录制视频
                },
                {
                  name: '远程盖印',
                  isOpen: true,
                  childSelect: [1, 2] //范围用印与录制视频
                }
              ]
              // options: ['常规盖印','连续盖印','远程盖印']
            },
            id: getId()
          },
          {
            title: '是否外带',
            controlName: 'TakeOut',
            controlType: '是否外带',
            configName: 'TakeOut',
            valueType: 'Boolean',
            type: 'TAKE_OUT',
            id: getId(),
            props: {
              disabledTitle: true,
              disabledDel: true,
              showTakeOut: true, //申请时是否显示，
              tipsText: '',
              items: [
                {
                  title: '起始时间',
                  controlName: 'DateTimeRange',
                  controlType: '日期区间',
                  icon: 'iconfont icon-kaoqin',
                  configName: 'DateTimeRange',
                  valueType: 'Array',
                  props: {
                    required: true,
                    showDuration: false,
                    enablePrint: true,
                    durationTitle: '时长', //时长控件名字
                    placeholder: ['开始时间', '结束时间'],
                    format: 'yyyy-MM-DD HH:mm'
                  },
                  id: getId(),
                  showLength: false,
                  showDuration: true
                },
                {
                  title: '外带地点',
                  controlName: 'Address',
                  configName: 'Address',
                  controlType: '地点',
                  valueType: 'Array',
                  props: {
                    required: true,
                    placeholder: '请选择外带位置'
                  },
                  id: getId()
                }
              ]
            }
          },
          {
            title: '预计用印日期',
            controlName: 'DateTime',
            controlType: '日期',
            configName: 'DateTime',
            valueType: 'Date',
            props: {
              required: false,
              disabledDel: false,
              format: 'yyyy-MM-DD'
            },
            id: getId()
          },
          {
            title: '申请事由',
            controlName: 'TextareaInput',
            controlType: '多行文本',
            configName: 'TextareaInput',
            valueType: 'String',
            props: {
              disabledDel: false,
              required: false
            },
            id: getId()
          },
          {
            title: '上传文件',
            controlName: 'FileUpload',
            controlType: '附件',
            configName: 'FileUpload',
            valueType: 'Array',
            type: 'FILE',
            props: {
              disabledDel: true,
              disabledTitle: true,
              required: false,
              placeholder: '请上传文件',
            },
            id: getId()
          }
        ]

  console.log(formItems, '----------')

  store.commit('loadForm', {
    ...store.state.process.design,
    formItems
  })
}
